.DocTypeLabelHeader {
  font-size: 1rem;
  color: #495057;
}

.DocTypeLabelCard {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1;
  max-width: 200px;
  min-width: 100px;
  min-height: 34px !important;
  height: 3vh !important;
  margin: 0.5rem 0.25rem !important;
  user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  background: white !important;
  border: none !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05) !important;
  border-radius: 8px !important;
  transition: all 0.2s ease !important;
}

.DocTypeLabelCard:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1) !important;
}

.DocTypeLabelContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8px !important;
}

@media (max-width: 400px) {
  .DocTypeLabelHeader {
    font-size: 0.75rem !important;
    padding-left: 5px !important;
  }
  .DocTypeLabelContent {
    padding: 6px !important;
  }
  .DocTypeLabelCard {
    padding: 4px !important;
    margin: 4px !important;
    margin-left: auto !important;
    margin-right: auto !important;
    width: 95% !important;
  }
  .DocTypeLabelIcon {
    font-size: 1.1rem !important;
  }
}

@media (min-width: 401px) {
  .DocTypeLabelHeader {
    font-size: 0.8rem !important;
    padding-left: 5px !important;
  }
  .DocTypeLabelIcon {
    font-size: 1.2rem !important;
  }
  .DocTypeLabelContent {
    padding: 8px !important;
  }
  .DocTypeLabelCard {
    min-height: 34px !important;
    padding: 6px !important;
    margin: 6px !important;
    margin-left: auto !important;
    margin-right: auto !important;
    width: 95% !important;
  }
}

@media (min-width: 768px) {
  .DocTypeLabelHeader {
    font-size: 0.9rem !important;
    padding-left: 8px !important;
  }
  .DocTypeLabelIcon {
    font-size: 1.3rem !important;
  }
  .DocTypeLabelContent {
    padding: 10px !important;
  }
  .DocTypeLabelCard {
    padding: 8px !important;
    margin: 8px auto !important;
    width: 98% !important;
  }
}
