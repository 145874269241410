.hovered_plus_button {
  opacity: 1;
  transition: all 0.3s ease;
  border-radius: 12px 0 0 12px;
  border: none;
  background: linear-gradient(135deg, #00b09b, #96c93d);
  box-shadow: 0 4px 15px rgba(0, 176, 155, 0.2);
  color: white;
  width: 3vw;
  height: 3vw;
  min-width: 50px;
  min-height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transform: translateX(0);
}

.not_hovered_plus_button {
  opacity: 0;
  transition: all 0.3s ease;
  border-radius: 12px 0 0 12px;
  border: none;
  background: linear-gradient(135deg, #00b09b, #96c93d);
  box-shadow: 0 4px 15px rgba(0, 176, 155, 0.2);
  color: white;
  width: 3vw;
  height: 3vw;
  min-width: 50px;
  min-height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transform: translateX(-100%);
}

.not_hovered_expand_button {
  opacity: 0;
  transition: all 0.3s ease;
  border-radius: 0 12px 12px 0;
  border: none;
  background: linear-gradient(135deg, #f6f8fa, #e9ecef);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  width: 1.5vw;
  height: 3vw;
  min-height: 50px;
  min-width: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 1rem;
  transform: translateX(100%);
}

.hovered_expand_button {
  opacity: 1;
  transition: all 0.3s ease;
  border-radius: 0 12px 12px 0;
  border: none;
  background: linear-gradient(135deg, #f6f8fa, #e9ecef);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  width: 1.5vw;
  height: 3vw;
  min-height: 50px;
  min-width: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 1rem;
  transform: translateX(0);
}

.collapsed_labels {
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  height: 3vw;
  min-height: 50px;
  overflow: hidden;
}

.expanded_labels {
  height: 20vh;
  min-height: 200px;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: rgba(155, 155, 155, 0.5) transparent;
}

.expanded_labels::-webkit-scrollbar {
  width: 6px;
}

.expanded_labels::-webkit-scrollbar-track {
  background: transparent;
}

.expanded_labels::-webkit-scrollbar-thumb {
  background-color: rgba(155, 155, 155, 0.5);
  border-radius: 20px;
  border: transparent;
}

.DocTypeWidget_ButtonFlexContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 10px;
}

.DocTypeWidget_BodySegment {
  width: 150px !important;
  min-height: 50px;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 0 0 12px 12px !important;
  border: none;
  background: white;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  padding: 12px;
  margin: 0 !important;
}

.DocTypeWidget_BodySegment_hovered {
  width: 150px !important;
  min-height: 50px;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 0 !important;
  border: none;
  background: white;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  padding: 12px;
  margin: 0 !important;
}

.DocTypeWidget_HeaderSegment {
  width: 150px !important;
  margin: 0 !important;
  padding: 10px !important;
  text-align: center;
  border-radius: 12px 12px 0 0;
  border: none;
  background: linear-gradient(135deg, #f8f9fa, #e9ecef);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
}

.DocTypeWidget_HeaderSegment .ui.horizontal.divider {
  margin: 0;
  opacity: 0.8;
}

.DocTypeWidget_HeaderSegment h5 {
  margin: 0;
  color: #495057;
  font-weight: 600;
  font-size: 0.9rem;
}

.DocTypeWidget_Container {
  background: transparent;
  border-radius: 12px;
  overflow: visible;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  margin-right: 16px;
}

.DocTypeWidget_Container:hover {
  box-shadow: none;
}

.DocTypeWidget_ContentFlexContainer {
  background: transparent;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  margin-top: 10px;
}

@media (max-width: 400px) {
  .DocTypeWidget_HeaderSegment {
    padding: 0.5rem !important;
  }
  .DocTypeWidget_BodySegment {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .DocTypeWidget_BodySegment_hovered {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
}

@media (min-width: 401px) {
  .DocTypeWidget_HeaderSegment {
    width: 150px !important;
  }
  .DocTypeWidget_BodySegment {
    width: 150px !important;
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .DocTypeWidget_BodySegment_hovered {
    width: 150px !important;
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .collapsed_labels {
    width: 150px !important;
  }
}

@media (min-width: 768px) {
  .DocTypeWidget_HeaderSegment {
    width: 200px !important;
  }
  .DocTypeWidget_BodySegment {
    width: 200px !important;
  }
  .DocTypeWidget_BodySegment_hovered {
    width: 200px !important;
  }
  .collapsed_labels {
    width: 200px !important;
  }
}

.add-doc-type-button.ui.circular.button {
  background: linear-gradient(135deg, #00b09b, #96c93d) !important;
  transition: all 0.2s ease !important;
  box-shadow: 0 4px 15px rgba(0, 176, 155, 0.2) !important;
}

.add-doc-type-button.ui.circular.button:hover {
  transform: translateY(-1px) !important;
  box-shadow: 0 6px 20px rgba(0, 176, 155, 0.3) !important;
}

.add-doc-type-button.ui.circular.button:active {
  transform: translateY(1px) !important;
  box-shadow: 0 2px 10px rgba(0, 176, 155, 0.2) !important;
}
